/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { 
  RiArrowLeftSLine, 
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiStarFill,
  RiStarLine,
  RiStarHalfFill,
  RiInformationLine,
  RiPriceTag3Line,
  RiUserSmileLine,
  RiComputerLine,
  RiCustomerService2Line,
  RiExternalLinkLine,
  RiUserHeartLine,
  RiQuestionLine,
  RiSettings3Line,
  RiUserLine,
  RiPlugLine,
  RiBarChart2Line,
  RiPaintBrushLine,
  RiLineChartLine,
  RiQuestionAnswerLine,
  RiTimeLine,
  RiGlobalLine,
  RiSmartphoneLine
} from "react-icons/ri"

const TimeAttendancePage = () => {
  // Time & Attendance Software comparison data
  const timeAttendanceSoftwareComparison = [
    {
      name: "Clockify",
      website: "https://clockify.me",
      priceRange: "$",
      pricingModel: "Freemium with paid plans",
      userRating: 4.7,
      userReviews: 860,
      usability: 4.8,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Time tracking with screenshots",
        "Timesheet approvals",
        "Project/client tracking",
        "Reporting dashboard",
        "Free tier available"
      ],
      limitations: [
        "Limited PTO management",
        "Basic scheduling capabilities",
        "Limited payroll integration in free tier",
        "Minimal compliance features"
      ],
      bestFor: "Small teams and freelancers looking for simple time tracking"
    },
    {
      name: "When I Work",
      website: "https://wheniwork.com",
      priceRange: "$$",
      pricingModel: "Per user/month (annual billing)",
      userRating: 4.5,
      userReviews: 790,
      usability: 4.6,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Shift scheduling",
        "Mobile clock in/out",
        "Group messaging",
        "Time off management",
        "Employee availability"
      ],
      limitations: [
        "Basic reporting tools",
        "Limited payroll integration",
        "Fewer enterprise features",
        "Limited advanced scheduling"
      ],
      bestFor: "Shift-based businesses needing scheduling and time tracking"
    },
    {
      name: "Homebase",
      website: "https://joinhomebase.com",
      priceRange: "$",
      pricingModel: "Freemium with tiered plans",
      userRating: 4.6,
      userReviews: 720,
      usability: 4.7,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Free clock in/out",
        "Employee scheduling",
        "Mobile timesheet management",
        "Team communication",
        "Labor cost tracking"
      ],
      limitations: [
        "Limited customization options",
        "Basic reporting capabilities",
        "Limited enterprise features",
        "Best for single-location businesses"
      ],
      bestFor: "Small retail, restaurant, and service businesses with hourly employees"
    },
    {
      name: "TimeClock Plus",
      website: "https://www.tcpsoftware.com",
      priceRange: "$$",
      pricingModel: "Per employee/month",
      userRating: 4.3,
      userReviews: 650,
      usability: 4.1,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Multiple clock-in methods",
        "Advanced scheduling",
        "Leave management",
        "Payroll integration",
        "Labor compliance tools"
      ],
      limitations: [
        "More complex interface",
        "Higher learning curve",
        "Implementation can be complex",
        "Higher cost structure"
      ],
      bestFor: "Organizations needing comprehensive workforce management with strong compliance features"
    },
    {
      name: "Kronos Workforce Ready",
      website: "https://www.ukg.com/solutions/workforce-ready",
      priceRange: "$$$",
      pricingModel: "Custom pricing based on modules",
      userRating: 4.2,
      userReviews: 680,
      usability: 3.9,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Comprehensive HCM suite",
        "Advanced time tracking",
        "Absence management",
        "Labor analytics",
        "Full payroll integration"
      ],
      limitations: [
        "Significant implementation effort",
        "Higher price point",
        "Complex admin interface",
        "Steeper learning curve"
      ],
      bestFor: "Enterprise organizations requiring sophisticated time, attendance, and workforce management"
    },
    {
      name: "ADP Workforce Now",
      website: "https://www.adp.com/what-we-offer/products/workforce-now.aspx",
      priceRange: "$$$",
      pricingModel: "Per employee/month, module-based",
      userRating: 4.3,
      userReviews: 820,
      usability: 4.1,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Full HR and payroll suite",
        "Time and attendance module",
        "Scheduling capabilities",
        "Compliance management",
        "Mobile punching"
      ],
      limitations: [
        "Higher cost structure",
        "Complex implementation",
        "Requires training for admins",
        "Better as full HRIS than standalone time solution"
      ],
      bestFor: "Mid-sized and large businesses looking for integrated time tracking within a full HRIS"
    },
    {
      name: "TSheets (QuickBooks Time)",
      website: "https://quickbooks.intuit.com/time-tracking",
      priceRange: "$$",
      pricingModel: "Per user/month plus base fee",
      userRating: 4.7,
      userReviews: 750,
      usability: 4.6,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "GPS time tracking",
        "QuickBooks integration",
        "Mobile app with geofencing",
        "Job/project tracking",
        "Team management"
      ],
      limitations: [
        "Less advanced scheduling features",
        "Limited enterprise capabilities",
        "Higher per-user cost at scale",
        "Best for QuickBooks users"
      ],
      bestFor: "Small businesses using QuickBooks for accounting and payroll"
    },
    {
      name: "Deputy",
      website: "https://www.deputy.com",
      priceRange: "$$",
      pricingModel: "Per user/month (modular pricing)",
      userRating: 4.6,
      userReviews: 730,
      usability: 4.7,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Advanced scheduling",
        "Facial recognition clock-in",
        "Shift swapping",
        "Labor cost optimization",
        "Auto-scheduling based on demand"
      ],
      limitations: [
        "Less robust PTO management",
        "More focused on scheduling than analytics",
        "Can get expensive for large teams",
        "Integration limitations"
      ],
      bestFor: "Businesses with complex shift scheduling needs and hourly workforces"
    },
    {
      name: "Paychex Flex Time",
      website: "https://www.paychex.com/time-attendance",
      priceRange: "$$$",
      pricingModel: "Per employee/month (part of Paychex Flex)",
      userRating: 4.2,
      userReviews: 640,
      usability: 4.0,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Multiple time collection options",
        "Geofencing capabilities",
        "Integrated with payroll",
        "Automated overtime calculation",
        "Labor distribution reporting"
      ],
      limitations: [
        "Interface can be dated",
        "Setup requires assistance",
        "Best as part of full Paychex solution",
        "Mobile app limitations"
      ],
      bestFor: "Businesses already using or considering Paychex for payroll services"
    },
    {
      name: "Replicon",
      website: "https://www.replicon.com/time-attendance",
      priceRange: "$$",
      pricingModel: "Per user/month (Cloud or on-premise)",
      userRating: 4.4,
      userReviews: 550,
      usability: 4.2,
      mobileFriendly: true,
      geoTracking: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Global time and attendance",
        "Advanced compliance features",
        "Project time tracking",
        "Customizable approval workflows",
        "Configurable business rules"
      ],
      limitations: [
        "Higher complexity for setup",
        "Steeper learning curve",
        "Can be overwhelming for small teams",
        "Premium pricing for advanced features"
      ],
      bestFor: "Global organizations with complex compliance requirements across multiple jurisdictions"
    }
  ];

  // Helper function to render star ratings
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    
    for (let i = 0; i < fullStars; i++) {
      stars.push(<RiStarFill key={`star-full-${i}`} />);
    }
    
    if (hasHalfStar) {
      stars.push(<RiStarHalfFill key="star-half" />);
    }
    
    const emptyStars = 5 - stars.length;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<RiStarLine key={`star-empty-${i}`} />);
    }
    
    return stars;
  };

  return (
    <Layout>
      <SEO 
        title="Top 10 Time & Attendance Software Compared | HR Software Guide"
        description="Find the best time & attendance software for your business - mobile-friendly time tracking, biometric options, and scheduling tools. Compare features, pricing, and user ratings for 2025."
        keywords={[
          "time and attendance software",
          "employee time tracking",
          "attendance management system",
          "workforce scheduling software",
          "time clock software",
          "employee scheduling",
          "biometric time attendance",
          "mobile time tracking app"
        ]}
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4
          }}>
            <Link to="/software" sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary',
              textDecoration: 'none',
              fontSize: '1rem',
              fontWeight: 500
            }}>
              <RiArrowLeftSLine sx={{ mr: 1 }} /> Back to HR Software
            </Link>
          </div>
          
          <div sx={{
            textAlign: 'center',
            mb: 2,
            fontSize: '0.95rem',
            color: 'text',
            opacity: 0.8
          }}>
            Last Updated: March 2025
          </div>
          
          <h1 sx={{
            fontSize: ['1.8rem', '2.3rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            Top 10 Time & Attendance Software Compared
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 5,
            lineHeight: 1.5,
            color: 'text'
          }}>
            A comprehensive comparison of the best time tracking, scheduling, and attendance management
            solutions to help you find the right software for your workforce management needs.
          </p>
          
          {/* Comparison criteria explanation */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: '1.3rem',
              fontWeight: 600,
              mb: 3,
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiInformationLine sx={{ mr: 2, color: 'primary' }} /> Comparison Criteria
            </h2>
            
            <div sx={{ 
              display: 'grid',
              gridTemplateColumns: '1fr',
              '@media (min-width: 640px)': {
                gridTemplateColumns: '1fr 1fr'
              },
              '@media (min-width: 1024px)': {
                gridTemplateColumns: 'repeat(3, 1fr)'
              },
              gap: 3,
              mb: 3
            }}>
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiPriceTag3Line sx={{ mr: 1, color: 'primary' }} /> Pricing
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  $ = Budget (Under $5/user/mo)<br />
                  $$ = Mid-range ($5-15/user/mo)<br />
                  $$$ = Premium ($15-30/user/mo)<br />
                  $$$$ = Enterprise (Custom pricing)
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiUserSmileLine sx={{ mr: 1, color: 'primary' }} /> User Rating
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Based on verified reviews across G2, Capterra, and software directories. Rating out of 5.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiComputerLine sx={{ mr: 1, color: 'primary' }} /> Usability
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Ease of use rating for both employees and administrators, considering interface, setup complexity, and mobile experience.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiGlobalLine sx={{ mr: 1, color: 'primary' }} /> Geo-Tracking
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Indicates whether the software offers GPS-based location tracking for remote clock-ins and geofencing capabilities.
                </p>
              </div>
            </div>
          </div>
          
          {/* Time & Attendance software comparison cards will go here */}
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', null, '1fr 1fr'],
            gap: 4,
            mb: 5
          }}>
            {timeAttendanceSoftwareComparison.map((software, index) => (
              <div key={`software-${software.name}`} sx={{
                bg: 'white',
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{
                  p: 3,
                  borderBottom: '1px solid',
                  borderColor: 'border'
                }}>
                  <div sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: 2
                  }}>
                    <h2 sx={{
                      fontSize: ['1.2rem', '1.3rem'],
                      fontWeight: 600,
                      mb: 1,
                      color: 'primary'
                    }}>
                      {software.name}
                    </h2>
                    <div sx={{
                      bg: 'primaryMuted',
                      px: 2,
                      py: 1,
                      borderRadius: '20px',
                      fontSize: '0.8rem',
                      fontWeight: 600,
                      color: 'primary'
                    }}>
                      {software.priceRange}
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2
                  }}>
                    <div sx={{ display: 'flex', color: 'amber' }}>
                      {renderStars(software.userRating)}
                    </div>
                    <div sx={{ ml: 2, fontSize: '0.9rem', opacity: 0.8 }}>
                      {software.userRating} ({software.userReviews} reviews)
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 3,
                    mb: 3
                  }}>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Pricing Model</div>
                      <div sx={{ fontSize: '0.9rem' }}>{software.pricingModel}</div>
                    </div>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Usability</div>
                      <div sx={{ fontSize: '0.9rem', display: 'flex', alignItems: 'center' }}>
                        {renderStars(software.usability)}
                        <span sx={{ ml: 1 }}>{software.usability}/5</span>
                      </div>
                    </div>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Mobile Friendly</div>
                      <div sx={{ fontSize: '0.9rem' }}>{software.mobileFriendly ? 'Yes' : 'No'}</div>
                    </div>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Geo-Tracking</div>
                      <div sx={{ fontSize: '0.9rem' }}>{software.geoTracking ? 'Yes' : 'No'}</div>
                    </div>
                    <div sx={{ gridColumn: '1 / span 2' }}>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Company Size</div>
                      <div sx={{ fontSize: '0.9rem' }}>{software.companySize}</div>
                    </div>
                  </div>
                  
                  <a href={software.website} target="_blank" rel="noopener noreferrer" 
                     aria-label={`Visit ${software.name} website`}
                     sx={{
                    display: 'block',
                    width: '100%',
                    py: 2,
                    textAlign: 'center',
                    bg: 'primary',
                    color: 'white',
                    fontWeight: 500,
                    borderRadius: '5px',
                    textDecoration: 'none',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                    ':hover': {
                      bg: 'primaryDark',
                      color: 'white',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                    }
                  }}>
                    Visit Website <RiExternalLinkLine sx={{ ml: 1, verticalAlign: 'middle' }} />
                  </a>
                </div>
                
                <div sx={{ p: 3, bg: 'background', flex: 1 }}>
                  <div sx={{ mb: 3 }}>
                    <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                      <RiCheckboxCircleLine sx={{ mr: 1, color: 'success' }} /> Key Features
                    </h3>
                    <ul sx={{ pl: 3, mb: 0 }}>
                      {software.keyFeatures.map((feature, i) => (
                        <li key={`feature-${software.name}-${i}`} sx={{ mb: 1, fontSize: '0.9rem' }}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                  
                  <div sx={{ mb: 3 }}>
                    <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                      <RiCloseCircleLine sx={{ mr: 1, color: 'danger' }} /> Limitations
                    </h3>
                    <ul sx={{ pl: 3, mb: 0 }}>
                      {software.limitations.map((limitation, i) => (
                        <li key={`limitation-${software.name}-${i}`} sx={{ mb: 1, fontSize: '0.9rem' }}>{limitation}</li>
                      ))}
                    </ul>
                  </div>
                  
                  <div>
                    <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                      <RiUserHeartLine sx={{ mr: 1, color: 'primary' }} /> Best For
                    </h3>
                    <p sx={{ mb: 0, fontSize: '0.9rem' }}>{software.bestFor}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Time & Attendance Software Selection Guide */}
          <div sx={{ mb: 6 }}>
            <h2 sx={{ fontSize: ['1.6rem', '1.8rem'], fontWeight: 700, mb: 4, textAlign: 'center' }}>
              Time & Attendance Software Selection Guide
            </h2>
            
            <div sx={{ 
              display: 'grid', 
              gridTemplateColumns: ['1fr', null, '1fr 1fr'],
              gap: 4
            }}>
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Compliance Requirements
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Ensure the software adheres to your local labor laws, overtime regulations, and break requirements. 
                  Look for built-in compliance features that automatically enforce rules and generate required reports for 
                  audit purposes.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Mobile Capabilities
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  With remote and field-based work increasing, mobile clocking options are essential for many organizations. 
                  Evaluate how the software handles remote check-ins, geolocation verification, and offline functionality 
                  for field workers with limited connectivity.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Integration Capabilities
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Consider how the time and attendance system will integrate with your existing HR, payroll, and scheduling software. 
                  Seamless data flow between systems reduces manual entry errors and streamlines processes from time tracking 
                  to paycheck distribution.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Time Tracking Methods
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Assess which time capture methods best suit your workforce: biometric scanners for security, 
                  mobile apps for remote teams, web-based clocking for office staff, or physical time clocks for 
                  manufacturing environments. The best solution may include multiple options.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Scheduling Features
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  If your organization requires complex scheduling, look for features that support shift management, 
                  self-service shift swapping, overtime monitoring, and automated schedule creation based on demand 
                  forecasting and employee availability.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Reporting & Analytics
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Powerful reporting capabilities help identify labor trends, manage costs, and optimize workforce 
                  deployment. Look for customizable dashboards, pre-built report templates, and the ability to create 
                  ad-hoc reports for specific business needs.
                </p>
              </div>
            </div>
          </div>
          
          {/* Time & Attendance Trends Section */}
          <div sx={{ mb: 6 }}>
            <h2 sx={{ fontSize: ['1.6rem', '1.8rem'], fontWeight: 700, mb: 4, textAlign: 'center' }}>
              Time & Attendance Industry Trends
            </h2>
            
            <div sx={{ 
              display: 'grid', 
              gridTemplateColumns: ['1fr', null, '1fr 1fr', '1fr 1fr 1fr'],
              gap: 4
            }}>
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Contactless Time Tracking
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  The pandemic accelerated adoption of touchless clock-in solutions like facial recognition, 
                  voice commands, and proximity-based technologies that eliminate shared surfaces while maintaining 
                  security and accuracy.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  AI-Powered Workforce Insights
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Advanced analytics and machine learning now provide predictive insights into labor trends, 
                  helping organizations optimize scheduling, predict absenteeism, and identify patterns that 
                  impact productivity and costs.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Flexible Work Arrangements
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Time and attendance systems now accommodate complex arrangements like hybrid schedules, 
                  floating start times, compressed workweeks, and result-based work tracking rather than 
                  strict time-based monitoring.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Enhanced Security Measures
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  To prevent time theft and "buddy punching," systems increasingly employ multi-factor authentication, 
                  biometric verification, and anomaly detection algorithms that flag suspicious patterns for review.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Employee Self-Service
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Modern systems empower employees with mobile access to view schedules, request time off, 
                  monitor accruals, and review worked hours, reducing administrative burden while increasing 
                  transparency and satisfaction.
                </p>
              </div>
              
              <div sx={{ 
                bg: 'white', 
                p: 3, 
                borderRadius: '10px',
                boxShadow: '0 3px 15px rgba(0,0,0,0.05)'
              }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  Real-Time Compliance Monitoring
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  With constantly evolving labor regulations, modern systems include real-time compliance monitoring that 
                  flags potential violations before they occur and automatically adjusts to regulatory changes.
                </p>
              </div>
            </div>
          </div>
          
          {/* FAQs Section */}
          <div sx={{ mb: 6 }}>
            <h2 sx={{ fontSize: ['1.6rem', '1.8rem'], fontWeight: 700, mb: 4, textAlign: 'center' }}>
              Frequently Asked Questions
            </h2>
            
            <div sx={{ maxWidth: '800px', mx: 'auto' }}>
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  How can time and attendance software reduce payroll errors?
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Time and attendance software eliminates manual time tracking, which is prone to errors, rounding discrepancies, 
                  and calculation mistakes. By automating the collection and calculation of work hours, overtime, and differentials, 
                  these systems reduce error rates by 80% or more. The software also provides audit trails, rule enforcement, 
                  and integration with payroll systems to ensure accurate compensation and compliance with labor regulations.
                </p>
              </div>
              
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  What's the difference between cloud-based and on-premises time tracking solutions?
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Cloud-based solutions are hosted by the vendor and accessed via the internet, requiring no on-site hardware 
                  beyond time clocks. They offer faster implementation, automatic updates, and lower upfront costs with a subscription 
                  pricing model. On-premises solutions are installed on your company's servers, providing greater customization, 
                  control over data security, and typically a one-time license fee with ongoing maintenance costs. Cloud solutions 
                  dominate the market due to their flexibility, mobile access, and reduced IT burden.
                </p>
              </div>
              
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                  How do biometric time clocks work, and are there privacy concerns?
                </h3>
                <p sx={{ fontSize: '0.95rem' }}>
                  Biometric time clocks use unique physical characteristics like fingerprints, facial features, or iris patterns 
                  to identify employees when they clock in or out. These systems convert biometric data into encrypted mathematical 
                  representations rather than storing actual images. Privacy concerns include proper consent, data security, and 
                  compliance with biometric privacy laws like Illinois' BIPA. Organizations must implement clear policies, secure 
                  storage practices, and alternative options for employees with concerns. Many jurisdictions now require explicit 
                  consent and disclosure of biometric data usage.
                </p>
              </div>
            </div>
          </div>
          
          {/* Call to Action for other HR software comparisons */}
          <div sx={{ mb: 6 }}>
            <h2 sx={{ fontSize: ['1.4rem', '1.6rem'], fontWeight: 700, mb: 4, textAlign: 'center' }}>
              Explore More HR Software Comparison Guides
            </h2>
            
            <div sx={{ 
              display: 'grid', 
              gridTemplateColumns: ['1fr', null, '1fr 1fr', 'repeat(4, 1fr)'],
              gap: 3,
              maxWidth: '900px',
              mx: 'auto'
            }}>
              <Link to="/software/hris" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                HRIS Systems
              </Link>
              
              <Link to="/software/applicant-tracking-system" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                ATS Software
              </Link>
              
              <Link to="/software/performance-management" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                Performance Tools
              </Link>
              
              <Link to="/software/employee-engagement" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                Engagement Platforms
              </Link>
            </div>
          </div>
          
          {/* Get Expert Help CTA */}
          <div sx={{
            bg: 'primary',
            borderRadius: '12px',
            p: 4,
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'], 
              fontWeight: 700, 
              color: 'white',
              mb: 3 
            }}>
              Need Help Selecting the Right Time & Attendance Software?
            </h2>
            <p sx={{ 
              fontSize: ['1rem', '1.1rem'], 
              color: 'white',
              mb: 4,
              opacity: 0.9
            }}>
              Our HR technology experts can provide personalized recommendations based on your organization's 
              specific needs, budget, and existing tech stack.
            </p>
            <div sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              justifyContent: 'center',
              gap: 3
            }}>
              <Link to="/contact" sx={{
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s, color 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'primaryDark',
                  color: 'white'
                }
              }}>
                Schedule a Free Consultation
              </Link>
              <Link to="/services/hr-software-selection" sx={{
                bg: 'transparent',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                border: '2px solid white',
                transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'primaryDark',
                  color: 'white'
                }
              }}>
                Learn About Our Selection Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TimeAttendancePage; 